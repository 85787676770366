$blue: #006CBE;
$darkblue: #1c2f77;

.grid{
    margin-left:-14px;
    
    display:flex;
    -webkit-box-lines:multiple;
    -moz-box-lines:multiple;
    box-lines:multiple;
    -webkit-flex-wrap:wrap;
    -moz-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
}
@media (max-width: 74.99em){
    .grid{
        margin-right:0
    }
}
@media (max-width: 61.9275em){
    .grid{
        margin-right:0
    }
}
.grid__item{
    width:calc(33.33% - 14px);
    color:#fff;
    margin-left:14px;
    margin-bottom:14px;
    padding:1em;
    position:relative;
    min-height:147px;
    background-color:#006CBE;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    
    flex:none;
    
    display:flex;
    
    flex-direction:column;
    
    -webkit-box-pack:end;
    -moz-box-pack:end;
    box-pack:end;
    -webkit-justify-content:flex-end;
    -moz-justify-content:flex-end;
    -ms-justify-content:flex-end;
    -o-justify-content:flex-end;
    justify-content:flex-end;
    -ms-flex-pack:end
}

.grid__item--large {
  width:calc(66.66% - 14px); 
}

.grid__timeline {
  display: flex;
  list-style: none;
  margin: 0.5em 0 2em 0;
  padding: 0;
  font-size: 0.7em;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: flex-end;

  @include mq($until: tablet) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.grid__timeline__item {
  position: relative;
  flex: 1;
  text-align: center;
  color: $darkblue;

  @include mq($until: tablet) {
    margin-bottom: 3rem;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 2em;
    width: 2em;
    left: 50%;
    left: calc(50% - 1em);
    bottom: -2.5em;
    background: white;
    border-radius: 50%;
    border: 1px solid $darkblue;
    z-index: 2;

    
  }
}

.grid__timeline__item + .grid__timeline__item {
  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    left: -50%;
    bottom: -1.5em;
    bottom: calc(-1.5em - 2px);
    background: $darkblue;

    @include mq($until: tablet) {
        content: none;
    }
  }
}

.grid__item--grey {
    color: $darkblue;
    background-color: #F1F1F1;
}

.grid__item h4{
    margin-top:0
}
@media (max-width: 61.9275em){
    .grid__item{
        width:calc(50% - 1rem)
    }
}
@media (max-width: 47.99em){
    .grid__item{
        width:calc(100% - 1rem)
    }
}
.grid__item a{
    text-decoration:none;
    color:#fff
}
.grid__item a:before{
    content:"";
    display:block;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%
}
.grid__item a.grid__item__link{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    padding:1em;
    
    flex:none;
    
    display:flex;
    
    flex-direction:column;
    
    -webkit-box-pack:end;
    -moz-box-pack:end;
    box-pack:end;
    -webkit-justify-content:flex-end;
    -moz-justify-content:flex-end;
    -ms-justify-content:flex-end;
    -o-justify-content:flex-end;
    justify-content:flex-end;
    -ms-flex-pack:end
}
.grid__item--green{
    background-color:#72C4CF;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.grid__item--blue{
    background-color:#2a2c87
}
.grid__item--card{
    
    justify-content:flex-start;
    
}
.grid__item--card > *{
    
    flex:none
}
.grid__item--card dt{
    font-size:34px;
    margin-top:0;
    font-weight:100;
    vertical-align:top;
    text-shadow:0 1px 1px rgba(0,0,0,0.2)
}
.grid__item--card dd{
    margin:0;
    text-shadow:0 1px 1px rgba(0,0,0,0.2)
}
.grid__item--card .super{
    font-size:68px
}
.grid__item--card p{
    margin:0;
    vertical-align:bottom;
    font-size:1em;
    line-height:1.25em;
    -webkit-align-self:flex-end;
    -moz-align-self:flex-end;
    align-self:flex-end;
    -ms-flex-item-align:end
}
@media (max-width: 47.99em){
    .grid__item--card{
        text-align:center;
        -webkit-box-pack:center;
        -moz-box-pack:center;
        box-pack:center;
        -webkit-justify-content:center;
        -moz-justify-content:center;
        -ms-justify-content:center;
        -o-justify-content:center;
        justify-content:center;
        -ms-flex-pack:center
    }
}
.grid__item--project{
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center;
    -webkit-box-pack:center;
    -moz-box-pack:center;
    box-pack:center;
    -webkit-justify-content:center;
    -moz-justify-content:center;
    -ms-justify-content:center;
    -o-justify-content:center;
    justify-content:center;
    -ms-flex-pack:center;
    height:30vw;
    max-height:315px;
    text-align:center;
    cursor:pointer
}
.grid__item--project .grid__title{
    font-size:18px;
    font-weight:900;
    text-transform:uppercase
}
.grid__p{
    font-size:13px;
    line-height:1.25;
    letter-spacing:-.03em;
    margin:0;
    margin-bottom:1em
}
.card__super{
    font-size:70px;
    font-weight:lighter;
    font-family:Lato;
    line-height:.8
}
.grid__title{
    margin-bottom:0;
    margin-top:.25em;
    line-height:1;
    font-size:1.25em;
    font-weight:400;
    text-shadow:0 1px 1px rgba(0,0,0,0.2);
    position:relative;
    z-index:100
}
.card__socials{
    background:none;
    padding:0;
    display:block;
    height:100%;
    position:relative;
    min-width:5px;
    
    display:flex;
    
    flex-direction:row;
    -ms-flex-direction:row
}
.card__socials > div{
    min-width:5px;
    
    display:flex;
    
    flex-direction:column;
    
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1
}
.card__socials a{
    
    display:flex;
    
    flex-direction:column;
    
    -webkit-box-pack:end;
    -moz-box-pack:end;
    box-pack:end;
    -webkit-justify-content:flex-end;
    -moz-justify-content:flex-end;
    -ms-justify-content:flex-end;
    -o-justify-content:flex-end;
    justify-content:flex-end;
    -ms-flex-pack:end;
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1
}
.card__socials a:before{
    position:inherit;
    display:none
}
.card__socials figure{
    
    display:flex;
    
    flex-direction:row;
    -ms-flex-direction:row
}
.card__socials img{
    height:52px;
    width:auto;
    margin:0 auto;
    -webkit-filter:drop-shadow(0px 1px 1px rgba(0,0,0,0.2));
    filter:drop-shadow(0px 1px 1px rgba(0,0,0,0.2))
}
.card__socials .twitter{
    background-color:#2DABDD;
    margin-right:7px
}
.card__socials .linkedin{
    background-color:#037BAB;
    margin-left:7px
}
.card__socials .linkedin img{
    height:53px
}
.card__socials .grid__title{
    margin-top:1em;
    width:100%;
    margin-top:.5em;
    padding-left:1em;
    margin-bottom:1em;
    
    flex:none;
    -webkit-align-self:flex-end;
    -moz-align-self:flex-end;
    align-self:flex-end;
    -ms-flex-item-align:end
}
@media (max-width: 47.99em){
    .order_1{
        -webkit-box-ordinal-group:1;
        -moz-box-ordinal-group:1;
        box-ordinal-group:1;
        -webkit-order:1;
        -moz-order:1;
        order:1;
        -ms-flex-order:1
    }
    .order_2{
        -webkit-box-ordinal-group:2;
        -moz-box-ordinal-group:2;
        box-ordinal-group:2;
        -webkit-order:2;
        -moz-order:2;
        order:2;
        -ms-flex-order:2
    }
    .order_3{
        -webkit-box-ordinal-group:3;
        -moz-box-ordinal-group:3;
        box-ordinal-group:3;
        -webkit-order:3;
        -moz-order:3;
        order:3;
        -ms-flex-order:3
    }
    .order_4{
        -webkit-box-ordinal-group:4;
        -moz-box-ordinal-group:4;
        box-ordinal-group:4;
        -webkit-order:4;
        -moz-order:4;
        order:4;
        -ms-flex-order:4
    }
    .order_5{
        -webkit-box-ordinal-group:5;
        -moz-box-ordinal-group:5;
        box-ordinal-group:5;
        -webkit-order:5;
        -moz-order:5;
        order:5;
        -ms-flex-order:5
    }
    .order_6{
        -webkit-box-ordinal-group:6;
        -moz-box-ordinal-group:6;
        box-ordinal-group:6;
        -webkit-order:6;
        -moz-order:6;
        order:6;
        -ms-flex-order:6
    }
    .order_7{
        -webkit-box-ordinal-group:7;
        -moz-box-ordinal-group:7;
        box-ordinal-group:7;
        -webkit-order:7;
        -moz-order:7;
        order:7;
        -ms-flex-order:7
    }
    .order_8{
        -webkit-box-ordinal-group:8;
        -moz-box-ordinal-group:8;
        box-ordinal-group:8;
        -webkit-order:8;
        -moz-order:8;
        order:8;
        -ms-flex-order:8
    }
    .order_9{
        -webkit-box-ordinal-group:9;
        -moz-box-ordinal-group:9;
        box-ordinal-group:9;
        -webkit-order:9;
        -moz-order:9;
        order:9;
        -ms-flex-order:9
    }
}
.grid__item--gradient-mask{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2;
    background-color:transparent;
    background-image:-webkit-linear-gradient(transparent,rgba(0,108,190,0.5));
    background-image:linear-gradient(transparent,rgba(0,108,190,0.5))
}




.grid__item--dark {
  background-color: $darkblue;
}

.grid__list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.8em;
  text-transform: uppercase;
  margin: 0 auto 1em;

  li {
    width: 33.33%;
    margin: .5em;
    padding: 1em;
    font-size: 0.9em;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    @include mq($until: tablet) {
        width: 100%;
    }
  }
}