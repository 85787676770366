.technos {
    display: flex;
    list-style: none;
    color: #2a2c87;
    margin: 0; padding: 0;
}
.technos__item {
    text-align: center;
    flex: 1;
    margin: 0; padding: 0;
    padding: 1em;
}
.technos__icon {
    padding: 1em;
    max-width: 7em;
}
.technos__caption {
    padding: 1em;
    border-top: 3px solid #4e85c2;
}


.iconHighlightDescription {
    width: 49.5%;
    text-align: center;
    background-color: #2766b1;
    color: white;
    display: inline-table;
    margin-left: -0.25rem;
}
.iconHighlightDescription + .iconHighlightDescription {
    margin-left: 0.5rem;
    margin-right: -0.25rem;
}
.iconHighlightDescription__icon {
    padding: 1em;
    margin-top: 0.5rem;
    max-height: 6em;
}
.iconHighlightDescription__highlight {
    padding: 1em;
     background-color: #2766b1;
     text-transform: uppercase;
     font-size: 21px;
     padding-top: 0;
     padding-bottom: 1.75rem;
     line-height: 1.225em;
     min-height: 4em;
}
.iconHighlightDescription__arrow {
    background-color: #83acd7;
}
.iconHighlightDescription__arrow img {
    height: auto;
    width: 100%
}
.iconHighlightDescription__texte {
    padding: 1em 2em;
    background-color: #83acd7;
    min-height: 160px;
}
.iconHighlightDescription__texte h1 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.225em;
}
.iconHighlightDescription__texte p {
    text-align: center;
}




.duoActionsAfrique {
    display: flex;
    margin-bottom: 3rem;
}
.duoActionsAfrique__block {
    flex: 1;                
}
.duoActionsAfrique h2 {
    text-transform: uppercase;
    font-weight: 700; 
    font-size: 20px;
    color: #2666B1;
    margin: 0;      
}
.duoActionsAfrique h3 {
    text-transform: uppercase;
    font-weight: 700; 
    font-size: 16px;
    color: #2666B1;
    margin-bottom: 1rem;         
}
.duoActionsAfrique h4 {
    text-transform: uppercase;
    font-weight: normal; 
    font-size: 14px;
    color: #2666B1;           
}
.duoActionsAfrique__texte {
    flex: 2;
    padding-left: 1rem;
}

.duoActionsAfrique__block__titre {
    background-color: #2666B1;
    padding: 2rem 1rem;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
}
.duoActionsAfrique__block__titre p {
    text-align: center !important;
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
}
.duoActionsAfrique__block__puissance {
    background-color: #4E85C2;
    font-size: 16px;
    font-weight: 400;
    padding: 2rem 1rem;
    color: white;
    height: 120px;
    display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.duoActionsAfrique__block h4 {
    margin-top: 0;
}
.duoActionsAfrique__block__puissance p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.duoActionsAfrique__block__technos {
    background-color: white;
    text-align: center;
    padding: 2rem 1rem;
    color: #2666B1;
    border: 4px solid #4E85C2;
}
.duoActionsAfrique__block__technos img {
    max-height: 50px;
    width: auto;
    margin: 0 5px;
}
