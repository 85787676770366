$offreblue: #006cbe;

.offres__poster {
  height: 18rem;
  display: block;
  max-height: 18rem;
  overflow: hidden;
  background-color: $offreblue;
  background-size: cover;
  position: relative;
}

.offres__intro {
  h1 {
    text-transform: uppercase;
    font-size: 1.5em;
    color: $offreblue;
    font-weight: 900;
  }
  
  border-bottom: 2px solid #F1F1F1;
  padding-bottom: 1em;
  margin-bottom: 2em;

  p {
    font-weight: bold;
    line-height: 1.618;
  }
}

.offres__listingEntries__list,
.offres__listingEntries__list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.offres__listingEntries__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.offres__listingEntries__item {
  flex: none;
  display: flex;
  width: 100%;
  border: 1px solid $blue;
  margin-bottom: 1em;
  
  p {
    font-size: 90%;
    line-height: 1.618;
    font-weight: normal;
    // text-align: center;
  }

  a, a:link {
    color: $blue;
    border-bottom: 1px solid;
  }
}

.offres__listingEntries__count {
  color: white;
  font-size: 1.2em;
  position: relative;

  span {
    position: absolute;
    top: -0.5em;
    left: -1.5em;
    width: 3em;
    height: 3em;
    display: flex;
    background: $blue;
    flex: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  @include mq($until: tablet) {
    display: none;
  }
}

.offres__listingEntries__content {
  padding: 1em 3em 2em 3em;

  @include mq($until: tablet) {
    padding: 1em;
  }

  .offres__listingEntries__titre {
    font-weight: bold;
    color: $blue;
    font-size: 1.25em;
    line-height: 1.25;
    text-align: left;
  }

  li {
    &:before {
      content: "→";
      margin-right: 0.5em;
    }
  }
  ul {
    margin-bottom: 1em;
  }
}

.offres__listingEntries__picture {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  color: white;
  background: $blue;
  font-size: 3em;
  background-size: cover;
  background-position: center;

  @include mq($until: tablet) {
    display: none;
  }
}

.offres__listing {
  h1, h2, h3, a {
    color: $offreblue;
  }
  margin-bottom: 2em;
}

.offres__listing__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.offres__listing__item {
  flex: none;
  width: 33.33%;
  text-align: center;
  padding: 1em 1em 2em;
  margin: 0;
  border: 0.5em solid white;
  background: #F1F1F1;

  @include mq($until: tablet) {
    width: 100%;
  }

  p {
    text-align: center;
    font-size: 90%;
    font-weight: normal;
    line-height: 1.618;
  }
  
  .offres__listing__titre {
    font-weight: bold;
    color: $offreblue;
    font-size: 1.25em;
    line-height: 1.25;
    text-align: center;
    min-height: 3.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

.offres__listing__icon {
  
  svg, img {
    height: 3em;
    width: auto;
    .stroke {
      stroke: $offreblue;
    }
    .fill {
      fill: $offreblue;
    }
  }

  &:before {
    content: "";
    display: block;
    height: 3em;
    width: 3em;
    background-color: #fff;
    border-radius: 50%;
    margin: -3em auto 1em;
  }
}

.offres__timeline {
  h1, h2, h3, a {
    color: $offreblue;
  }
  margin-bottom: 2em;
}

.offres__timeline__list {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.offres__timeline__item {
  flex: none;
  width: 33.33%;
  text-align: center;
  padding: 1em;
  margin: 0;
  border: 0.5em solid white;
  background: #F1F1F1;
  position: relative;
  color: $blue;
  font-weight: bold;

  p {
    text-align: center;
    font-size: 90%;
    font-weight: normal;
    line-height: 1.618;
    padding-bottom: 1em;
    margin: 0;
  }
}

.offres__timeline__count {
  display: block;
  height: 2.5em;
  width: 2.5em;
  background: $blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0.5em;
  color: white;
}

.offres__timeline__item + .offres__timeline__item {
  .offres__timeline__count {
    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background: $blue;
      border-radius: 50%;
      position: absolute;
      top: 2.25em;
      left: -50%;
      z-index: 0;
    }
  }
}


.offres__cta {
  margin-top: 1em;
  padding-top: 2em;
  border-top: 2px solid #F1F1F1;

  a, a:link {
    color: white;
    border-bottom: 1px solid;
  }

  p {
    margin-bottom: 0.5em;
  }
}

.offres__cta__title {
  color: $offreblue;
  margin-bottom: 0.5em;
}

.offres__cta__content {
background-color: $offreblue;
  color: white;
  padding: 1em;
  
  p {
    line-height: 1.618;             
  }
}

.offres__prerequis {
  margin-bottom: 2em;
}

.offres__buildings {
  margin-bottom: 2em;
}

.offres__buildings__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.offres__buildings__item {
  flex: none;
  width: 50%;
  padding: 0.5em;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }
}

.offres__notice {
  background: #F1F1F1;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $blue;
  border-radius: 4px;
  margin-bottom: 2em;

  div {
    flex: 1;
  }

  .offres__icon {
    padding: 1em;
    width: 6em;
    flex: none;
    text-align: center;
  }

  img, svg {
    width: 4em;
    height: auto;
  }
}

.offres__listing__titre.super__num {
  font-size: 3em;
  padding: 1em;
  line-height: 0;
  min-height: 0;
}

.offres__focus {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 2px solid #f1f1f1;
  display: block;
}

.offres__focus__content {
  background-color: #f1f1f1;
  padding: 1em;
  color: #006cbe;
  border-top: 4px solid $blue;
  border-bottom: 4px solid $blue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offres__focus__vignette {
  flex: none;
  margin-right: 2em;

  img, svg {
    width: 5em;
    height: 5em;
  }

  @include mq($until: tablet) {
    display: none;
  }
}