$blue: #006CBE;
$lightblue: #72C4CF;
$darkblue: #1c2f77;

.header {
  margin-bottom: 1rem;
}

.menu__extras {
  display: flex;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-left: 2rem;
  }

  li {
    margin: 0;
    margin-left: 0.5rem;
  }

  img, svg {
    width: 2rem;
    height: auto;
  }
}

.menu__lang {
  a {
    text-decoration: none;
    line-height: 1.8;
    vertical-align: bottom;
    text-transform: uppercase;
    font-size: 0.8em;
  }
  .menu__item {
    vertical-align: bottom;
    height: 2rem;
    width: 2rem;    
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #006CBE;
    a { color: white; }
  }

  .menu__item--inactive {
    background: white;
    a { color: #006CBE; }
  }

   @include mq($until: tablet) {
    .menu__item:not(.menu__item--inactive) {
      display: none;
    }
  }
}

ul.menu__socials {
  @include mq($until: tablet) {
    display: none;
  }
}

.menu-container {
  width: 100%;
  margin: 0 auto;
  background: #006CBE;
}

.menu-mobile {
  display: none;
  padding: 20px;
}

.menu-container {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 1px;
  border-top: 1px solid $darkblue;
}

.menu {
  > ul {
    margin: 0 auto;
    width: 100%;
    list-style: none;
    padding: 0;
    position: relative;
    z-index: 101;
    box-sizing: border-box;
    display: flex;
    justify-content: stretch;
    font-size: 0.8rem;
    &:before,
    &:after {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    > li:hover a,
    > li:hover span {
      color: white;
    }
    > li {
      float: left;
      padding: 0;
      margin: 0;
      flex: 1;
      text-align: center;
      > a, > span {
        color: $darkblue;
        text-transform: uppercase;
      }
      a, span {
        text-decoration: none;
        padding: 0.6em 1.6em;
        display: block;
      }
      &:hover {
        background: lighten($blue, 5%);
      }
      > ul {
        display: none;
        width: 100%;
        background: lighten($blue, 5%);
        padding: 20px;
        position: absolute;
        z-index: 99;
        left: 0;
        margin: 0;
        list-style: none;
        box-sizing: border-box;
        border-bottom: 3px solid $blue;
        text-align: left;
        font-size: 0.95rem;
        &:before,
        &:after {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        > li {
          margin: 0;
          padding-bottom: 0;
          list-style: none;
          width: 33.33%;
          background: none;
          float: left;
          a, span {
            color: white;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
            padding: .2em 0;
            width: 95%;
            display: block;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $blue;
            font-weight: bold;
          }
          > ul {
            display: block;
            padding: 0;
            margin: 10px 0 0;
            list-style: none;
            box-sizing: border-box;
            &:before,
            &:after {
              content: "";
              display: table;
            }
            &:after {
              clear: both;
            }
            > li {
              float: left;
              width: 100%;
              padding: 5px 0;
              margin: 0;
              font-size: 1em;
              // text-transform: uppercase;
              a {
                border: 0;

                &:before {
                  content: "•";
                  margin-right: 0.5em;
                }
              }
            }
          }
        }
        &.normal-sub {
          width: 300px;
          left: auto;
          padding: 10px 20px;
          > li {
            width: 100%;
            a {
              border: 0;
              padding: 1em 0;
            }
          }
        }
      }
    }
  }
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media only screen and (max-width: 959px) {
  .menu-container {
    width: 100%;
  }
  .menu-mobile {
    display: block;
  }
  .menu-dropdown-icon {
    &:before {
      display: block;
    }
  }
  .menu {
    border-top: 2px solid #006CBE;

    > ul {
      display: none;
      > li {
        width: 100%;
        float: none;
        display: block;
        a, span {
          padding: 1.5em;
          width: 100%;
          display: block;
        }
        > ul {
          position: relative;
          &.normal-sub {
            width: 100%;
          }
          > li {
            float: none;
            width: 100%;
            margin-top: 20px;
            &:first-child {
              margin: 0;
            }
            > ul {
              position: relative;
              > li {
                float: none;
              }
            }
          }
        }
      }
    }
    .show-on-mobile {
      display: block;
    }
  }
}