@import '../../node_modules/sass-mq/mq';

@import "base/typography";
@import "legacy/app";
@import "legacy/app-addons";

@import "components/menu";
@import "components/grid";
@import "components/offres";
@import "components/accordion";
@import "components/formulaire";

.heading__section {
  a {
    color: white;
    text-decoration: none;
  }

  display: inline;
}

.heading__section + .heading__section {
  margin-left: 0.5em;

  &:before {
    content: ">";
    margin: 0;
    padding: 0;
    margin-right: 0.5em;
    font-weight: normal;
  }
}