.accordion__list {
    list-style: none;
    padding: 0;

    img {
      max-width: 100%;
      height: auto;
      margin: 2em auto 1em;
      display: block;
    }
  
    .accordion__inner {
        padding: 2em 1em;
        overflow: hidden;
        display: none;
      
        &.accordion--show {
          /*display: block;*/
        }
    }
  
    li {
        margin: .5em 0;

        a.accordion__toggle {
            width: 100%;
            display: flex;
            align-items: center;
            background: $blue;
            color: #fff;
            padding: .75em 1em;
            border-radius: 0.15em;
            transition: background .3s ease;
            font-size: 90%;
          
            &:hover {
                background: darken($blue, 10%);
            }
        }
    }
}

.accordion__question {
  display: flex;
  align-items: center;
  float: left;
}

.accordion__question svg,
.accordion__question img {
  height: 2em;
  width: 2em;
  margin-right: 0.5em;
}