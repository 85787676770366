@charset "UTF-8";
html{
    font-family:sans-serif;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%
}
body{
    margin:0
}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section,summary{
    display:block
}
audio,canvas,progress,video{
    display:inline-block
}
audio:not([controls]){
    display:none;
    height:0
}
progress{
    vertical-align:baseline
}
template,[hidden]{
    display:none
}
a{
    background-color:transparent
}
a:active,a:hover{
    outline-width:0
}
abbr[title]{
    border-bottom:none;
    text-decoration:underline;
    text-decoration:underline dotted
}
b,strong{
    font-weight:inherit;
    font-weight:bolder
}
dfn{
    font-style:italic
}
h1{
    font-size:2em;
    margin:.67em 0
}
mark{
    background-color:#ff0;
    color:#000
}
small{
    font-size:80%
}
sub,sup{
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline
}
sub{
    bottom:-.25em
}
sup{
    top:-.5em
}
img{
    border-style:none
}
svg:not(:root){
    overflow:hidden
}
code,kbd,pre,samp{
    font-family:monospace,monospace;
    font-size:1em
}
figure{
    margin:0
}
hr{
    box-sizing:content-box;
    height:0;
    overflow:visible
}
button,input,select,textarea{
    font:inherit
}
optgroup{
    font-weight:700
}
button,input,select{
    overflow:visible
}
button,input,select,textarea{
    margin:0
}
button,select{
    text-transform:none
}
button,[type="button"],[type="reset"],[type="submit"]{
    cursor:pointer
}
[disabled]{
    cursor:default
}
button,html [type="button"],[type="reset"],[type="submit"]{
    -webkit-appearance:button
}
button::-moz-focus-inner,input::-moz-focus-inner{
    border:0;
    padding:0
}
button:-moz-focusring,input:-moz-focusring{
    outline:1px dotted ButtonText
}
fieldset{
    border:1px solid silver;
    margin:0 2px;
    padding:.35em .625em .75em
}
legend{
    box-sizing:border-box;
    color:inherit;
    display:table;
    max-width:100%;
    padding:0;
    white-space:normal
}
textarea{
    overflow:auto
}
[type="checkbox"],[type="radio"]{
    box-sizing:border-box;
    padding:0
}
[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button{
    height:auto
}
[type="search"]{
    -webkit-appearance:textfield
}
[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration{
    -webkit-appearance:none
}
body{
    font-family:"Lato",Helvetica,Arial,sans-serif;
    font-weight:400;
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    -webkit-tap-highlight-color:transparent;
    -webkit-tap-highlight-color:transparent;
    line-height:1.5;
    color:#333;
    font-size:16px
}
*,:before,:after{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
ul,ol,dl{
    margin:0 0 1.3em;
    padding:0;
    list-style-position:inside
}
ul:not(ol),ol:not(ol),dl:not(ol){
    list-style-type:circle
}
ul li,ol li,dl li{
    margin-bottom:.3em
}
ul dt,ol dt,dl dt{
    margin-top:.3em
}
ul dd,ol dd,dl dd{
    margin-left:2rem
}
ul ul,ul ol,ul dl,ol ul,ol ol,ol dl,dl ul,dl ol,dl dl{
    margin:.5rem 0 .4rem;
    padding-left:2rem;
    font-size:90%
}
#app{
    position:relative
}
.header{
    margin-top:3rem;
    margin-bottom:2rem;
    
    display:flex;
    -webkit-box-pack:justify;
    -moz-box-pack:justify;
    box-pack:justify;
    -webkit-justify-content:space-between;
    -moz-justify-content:space-between;
    -ms-justify-content:space-between;
    -o-justify-content:space-between;
    justify-content:space-between;
    -ms-flex-pack:justify;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center
}
.no_scroll--js{
    overflow:hidden
}
.container{
    max-width:calc(974px + 2rem);
    padding:0 1rem;
    margin:0 auto
}
@media (max-width: 47.99em){
    .hide-on-mobile{
        display:none
    }
}
.address,address{
    font-size:18px;
    font-style:inherit;
    margin:1rem auto 0;
    text-align:center
}
.address .hero{
    font-size:36pt;
    margin:0;
    font-weight:400
}
.invisible{
    visibility:hidden
}

.identite{
    position:relative;
    z-index:100
}
.identite img{
    max-width:193px;
    height:auto
}

.content{
    transition:opacity 150ms ease
}

.img--triptyque{
    margin-left:-10px;
    margin-right:-10px;
    margin-bottom:2rem;
    color:#fff;
    font-size:30px;
    font-weight:100;
    text-transform:uppercase;
    width:100%;
    
    display:flex;
    
    flex-direction:row;
    -ms-flex-direction:row
}
.img--triptyque figure{
    padding:0 10px;
    text-align:center;
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1
}
.img--triptyque figcaption{
    padding:4rem 0;
    margin:0 auto
}
@media (max-width: 47.99em){
    .img--triptyque{
        
        flex-direction:column;
        
    }
    .img--triptyque figure{
        margin-bottom:1rem
    }
    .img--triptyque figure img{
        height:auto
    }
}
.content__main .img--triptyque p{
    text-align:center
}
.triptyque__num{
    display:block;
    font-weight:800;
    margin-bottom:.3rem
}
p.triptyque__title{
    font-weight:400;
    font-size:14px;
    line-height:1.35;
    margin-bottom:.3rem
}
p.triptyque__description{
    font-size:13px;
    text-transform:none;
    font-weight:400
}
.img--triptyque--icons{
    margin-bottom:2rem;
    width:auto
}
.img--triptyque--icons img{
    margin-bottom:.5rem
}
.img--triptyque--icons figure{
    background-color:#72C4CF;
    padding:1rem;
    margin:0 5px;
    
    display:flex;
    
    flex-direction:column;
    
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center;
    -webkit-box-pack:center;
    -moz-box-pack:center;
    box-pack:center;
    -webkit-justify-content:center;
    -moz-justify-content:center;
    -ms-justify-content:center;
    -o-justify-content:center;
    justify-content:center;
    -ms-flex-pack:center
}
.img--triptyque--icons figcaption{
    font-size:18px;
    padding:0;
    text-transform:uppercase
}
@media (max-width: 47.99em){
    .img--triptyque--icons figure{
        margin-bottom:1rem
    }
    .img--triptyque--icons .img--triptyque-visuel{
        display:none
    }
}
.img--triptyque--areva{
    width:inherit
}
.img--triptyque--areva figure{
    width:auto
}
.img--triptyque--areva img{
    width:100%;
    height:auto
}
.img--triptyque--spaced figure{
    margin:.5rem
}
@media (max-width: 47.99em){
    .img--triptyque--spaced figure{
        margin:0 0 .5rem
    }
}
.img--triptyque--steps figure{
    padding-left:10px;
    padding-right:10px;
    padding-bottom:5px;
    
    justify-content:flex-start;
    ;
    
    align-items:flex-start;
    
}
.img--triptyque--steps figure:nth-child(1){
    background-color:#2766b1
}
.img--triptyque--steps figure:nth-child(2){
    background-color:#4e85c2
}
.img--triptyque--steps figure:nth-child(3){
    background-color:#83acd7
}
.img--triptyque--steps figcaption{
    
    justify-content:flex-start;
    ;
    
    align-items:flex-start;
    
}
.img--triptyque--steps figcaption > *{
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1
}
.img--triptyque--steps .triptyque__title{
    font-size:15px;
    font-weight:400;
    text-shadow:0 1px 1px rgba(0,0,0,0.2)
}
.img--triptyque--steps .triptyque__description{
    font-size:13px;
    font-weight:400
}
.img--triptyque--doublesteps figure{
    background-color:#2a2c87;
    padding:30px
}
.img--triptyque--doublesteps figcaption{
    
    justify-content:flex-start;
    ;
    
    align-items:flex-start;
    
}
.img--triptyque--doublesteps figcaption > *{
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1
}
.img--triptyque--doublesteps .triptyque__title{
    font-size:21px;
    font-weight:400;
    text-shadow:0 1px 1px rgba(0,0,0,0.2)
}
.img--triptyque--doublesteps .triptyque__description{
    font-size:13px;
    font-weight:400
}
.bg_container{
    position:relative;
    overflow:hidden;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    backgorund-color:#006CBE
}
.bg_container > *{
    position:relative;
    z-index:2
}
.bg_container img.bg{
    position:absolute;
    left:0;
    top:0;
    z-index:1;
    height:auto;
    min-height:100%;
    width:auto;
    min-width:100%;
    opacity:0;
    transition:opacity 1s
}
.bg_container img.bg.is-loaded{
    opacity:1
}
.img--lazy{
    opacity:0;
    background-color:#e6e6e6;
    transition:opacity 1s
}
.img--lazy.is-loaded{
    opacity:1
}
.podium{
    color:#fff;
    padding:3.2rem;
    margin-bottom:14px;
    height:350px;
    padding-bottom:2.6em;
    background-color:#006CBE;
    
    display:flex;
    
    flex-direction:column;
    
    -webkit-box-pack:end;
    -moz-box-pack:end;
    box-pack:end;
    -webkit-justify-content:flex-end;
    -moz-justify-content:flex-end;
    -ms-justify-content:flex-end;
    -o-justify-content:flex-end;
    justify-content:flex-end;
    -ms-flex-pack:end
}
@media (max-width: 47.99em){
    .podium{
        display:none
    }
}
.podium__title{
    font-size:3.1em;
    font-weight:900;
    text-transform:uppercase;
    text-shadow:1px 2px 1px rgba(0,0,0,0.3);
    margin-bottom:0;
    line-height:1.2
}
.heading{
    text-transform:uppercase;
    padding:22px 55px 31px;
    color:#fff;
    background-color:#2a2c87;
    background-image:-webkit-linear-gradient(top,#2a2c87,#262879);
    background-image:linear-gradient(to bottom,#2a2c87,#262879)
}
.heading__section,.heading__title{
    margin:0;
    line-height:1;
    margin-bottom:10px;
    text-shadow:0 1px 1px rgba(0,0,0,0.2)
}
.heading__section{
    font-size:24px;
    font-weight:900
}
.heading__title{
    font-weight:100;
    font-size:40px
}
.heading--offre{
    background-color:#006cbe;
    background-image:-webkit-linear-gradient(top,#006cbe,#0061ab);
    background-image:linear-gradient(to bottom,#006cbe,#0061ab)
}
.heading--centrales{
    background-color:#3b8ecd;
    background-image:-webkit-linear-gradient(top,#3b8ecd,#3580b8);
    background-image:linear-gradient(to bottom,#3b8ecd,#3580b8)
}
.heading--contact{
    background-color:#6eb5ea;
    background-image:-webkit-linear-gradient(top,#6eb5ea,#63a3d2);
    background-image:linear-gradient(to bottom,#6eb5ea,#63a3d2)
}
.heading--actu{
    background-color:#a9d0f7;
    background-image:-webkit-linear-gradient(top,#a9d0f7,#8cb1d3);
    background-image:linear-gradient(to bottom,#a9d0f7,#8cb1d3)
}
@media (max-width: 33.75em){
    .heading{
        padding:22px 22px 31px;
        background-color:#182d73;
        color:#fff
    }
    .heading__title{
        font-size:20px;
        line-height:1.35em
    }
}
.content__main{
    padding:20px 60px 60px;
    position:relative
}
.content__main section{
    padding:10px 0
}
.content__main h3{
    text-transform:uppercase;
    color:#006CBE;
    font-size:24px;
    margin-top:0;
    font-weight:900
}
.content__main p{
    margin-top:0;
    font-size:18px;
    font-weight:100;
    line-height:1.225em;
    text-align:justify
}
.content__main a,.content__main a:visited,.content__main a:link{
    color:#02C1CF;
    font-weight:500;
    text-decoration:none
}
.content__main hr{
    border:none;
    border-top:9px solid #e6e6e6;
    margin:1.5rem 0
}
.content__main--no-bottom{
    padding-bottom:20px
}
.spacer--xs{
    display:block;
    margin-bottom:1rem
}
.spacer{
    display:block;
    margin-bottom:2.5rem
}
.spacer--m{
    display:block;
    margin-bottom:3.5rem
}
.spacer--xl{
    display:block;
    margin-bottom:5rem
}
.spacer--xxl{
    display:block;
    margin-bottom:10rem
}
.project-download a{
    margin:2rem 0;
    
    display:flex;
    
    flex-direction:row;
    -ms-flex-direction:row;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center
}
.project-download a i{
    margin-right:1em;
    
    display:flex;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center
}
.project-download a p{
    font-weight:600;
    color:#0058ad;
    margin-bottom:0
}
.text-with-image{
    
    display:flex
}
.text-with-image__img{
    
    flex:none;
    width:33.33%;
    text-align:center
}
.text-with-image__img img{
    height:100%;
    width:auto;
    margin:0 auto
}
.text-with-image__text{
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1;
    padding-left:2rem
}
@media (max-width: 33.75em){
    .content__main{
        padding:20px 20px 60px
    }
    .text-with-image__img{
        display:none
    }
    .text-with-image__text{
        padding-left:inherit
    }
}
@media (max-width: 61.9275em){
    .text-with-image__img{
        display:none
    }
    .text-with-image__text{
        padding-left:inherit
    }
}
.duo-grid{
    margin-bottom:10px
}
.duo-grid figure{
    
    display:flex;
    min-height:376px
}
.duo-grid .img-container{
    
    flex:none;
    width:50%
}
.duo-grid img{
    width:100%;
    height:auto
}
.duo-grid figcaption{
    
    flex:none;
    width:50%;
    height:auto;
    padding:35px 40px 40px;
    background-color:#F0F1F2
}
h3.duo-grid__title{
    font-size:24px;
    color:#000;
    font-weight:100;
    line-height:1;
    margin:0;
    margin-bottom:1rem
}
h4.duo-grid__status{
    font-size:14px;
    font-weight:800;
    margin:0;
    line-height:1;
    margin-bottom:1rem
}
p.duo-grid__description{
    font-size:12px;
    font-weight:400
}
.duo-grid__linkedin{
    height:21px;
    width:23px
}
.duo-grid__linkedin img{
    width:auto
}
@media (max-width: 47.99em){
    .duo-grid figure{
        
        flex-direction:column;
        
    }
    .duo-grid figure .img-container{
        width:100%;
        max-height:450px;
        -webkit-box-ordinal-group:-1;
        -moz-box-ordinal-group:-1;
        box-ordinal-group:-1;
        -webkit-order:-1;
        -moz-order:-1;
        order:-1;
        -ms-flex-order:-1
    }
    .duo-grid figure .img-container img{
        width:100%;
        height:auto;
        position:relative;
        min-height:inherit;
        min-width:inherit;
        top:inherit;
        left:inherit
    }
    .duo-grid figure figcaption{
        width:100%
    }
}
.accordion section > div{
    opacity:0
}
.accordion h3{
    position:relative;
    z-index:10;
    padding:1rem 60px;
    margin:0 -60px;
    background-color:#f1f2f2;
    
    display:flex;
    
    flex-direction:row;
    -ms-flex-direction:row;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center
}
.accordion h3:hover{
    cursor:pointer
}
.accordion h3 + div{
    pointer-events:none;
    visibility:hidden;
    overflow:hidden
}
.accordion h3.slide--active + div{
    pointer-events:auto;
    visibility:inherit
}
.accordion__ico{
    width:180px;
    margin-left:-60px;
    text-align:center;
    
    flex:none
}
.accordion__title{
    color:#32589f;
    font-size:30px;
    font-weight:400;
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1
}
.accordion__close{
    background:url(/img/ui/ui-arrow-up.png) center center no-repeat;
    display:block;
    width:47px;
    height:17px;
    
    flex:none;
    -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    transform:rotate(180deg)
}
.slide--active .accordion__close{
    -webkit-transform:rotate(0deg);
    -moz-transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    -o-transform:rotate(0deg);
    transform:rotate(0deg)
}
@media (max-width: 33.75em){
    .accordion__ico{
        display:none
    }
    .accordion__close{
        width:15px;
        height:10px;
        background-size:100%
    }
    .accordion__title{
        font-size:14px;
        line-height:1.35em
    }
    .accordion h3{
        margin:0 -20px;
        padding-left:20px;
        padding-right:20px
    }
}
.img--full-width{
    width:100%;
    height:auto;
    margin-bottom:1em
}
.img--1-3{
    width:100%;
    margin-left:-4px;
    margin-right:-4px;
    height:auto;
    
    display:flex
}
.img--1-3 img{
    margin:0 4px;
    width:100%;
    height:auto
}
@media (max-width: 47.99em){
    .img--1-3 figure:first-child{
        width:100%;
        margin:0 auto
    }
    .img--1-3 figure:last-child{
        display:none
    }
}
img[data-echo]{
    opacity:0;
    transition:opacity 300ms ease
}
img.is-loaded{
    opacity:1
}
.Wallop{
    position:relative
}
.Wallop-list{
    position:relative;
    overflow:hidden
}
.Wallop-item{
    visibility:hidden;
    position:absolute;
    top:0;
    left:0;
    width:100%
}
.Wallop-item--current{
    visibility:visible;
    position:relative
}
.Wallop-buttonPrevious,.Wallop-buttonNext{
    position:absolute;
    top:50%;
    display:block;
    width:60px;
    height:60px;
    margin-top:-30px;
    border:none;
    z-index:1000;
    cursor:pointer;
    background:url(/img/ui/ui-arrow-left.png) center center no-repeat #32589f
}
.Wallop-buttonPrevious:focus,.Wallop-buttonNext:focus{
    outline:none
}
.Wallop-buttonPrevious{
    left:0
}
.Wallop-buttonNext{
    right:0;
    -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    transform:rotate(180deg)
}
.slider{
    position:relative
}
.slider li{
    position:relative;
    display:inline-block
}
.frame{
    position:relative;
    font-size:0;
    line-height:0;
    overflow:hidden;
    white-space:nowrap
}
.slides{
    display:inline-block
}
.map-implantations{
    margin-bottom:8px;
    position:relative
}
.map-implantations img{
    height:auto;
    width:100%
}
.map-implantations figcaption{
    position:absolute;
    top:2em;
    left:2em
}
.map-implantations__list{
    list-style:none;
    display:table-row
}
.map-implantations__label{
    text-transform:uppercase;
    color:#2a2c87;
    font-weight:600;
    font-size:1em;
    padding-left:1em;
    display:table-cell
}
.map-implantations__ico{
    height:1.3em;
    width:1.5em;
    display:table-cell
}
.map-implantations__ico img{
    height:100%;
    width:auto
}
@media (max-width: 47.99em){
    .map-implantations figcaption{
        position:static;
        font-size:.7em;
        margin-top:.5rem
    }
}
.actu__article{
    margin-bottom:4em
}
.actu__date{
    margin:0;
    font-size:18px;
    font-size:18pt;
    font-weight:100;
    line-height:1
}
.actu__title{
    margin:0;
    font-size:30px;
    font-size:30pt;
    font-weight:100;
    margin-bottom:1rem
}
.content__main .actu__title a{
    color:#000;
    font-weight:100
}
.socialbar{
    background-color:#e9e9e9;
    list-style:none;
    
    display:flex;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center;
    -webkit-box-pack:center;
    -moz-box-pack:center;
    box-pack:center;
    -webkit-justify-content:center;
    -moz-justify-content:center;
    -ms-justify-content:center;
    -o-justify-content:center;
    justify-content:center;
    -ms-flex-pack:center
}
.socialbar li{
    margin:1em;
    
    display:flex;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center;
    -webkit-box-pack:center;
    -moz-box-pack:center;
    box-pack:center;
    -webkit-justify-content:center;
    -moz-justify-content:center;
    -ms-justify-content:center;
    -o-justify-content:center;
    justify-content:center;
    -ms-flex-pack:center
}
.socialbar img{
    height:auto;
    width:auto
}
.project-overlay{
    position:fixed;
    opacity:0;
    pointer-events:none;
    visibility:hidden;
    z-index:200;
    background-color:rgba(0,0,0,0.8);
    top:0;
    left:0;
    width:100%;
    height:100%;
    transition:opacity 250ms ease
}
.project-overlay .Wallop-buttonPrevious,.project-overlay .Wallop-buttonNext{
    background-color:transparent
}
.project-overlay .slider{
    height:100%
}
.project-overlay .frame{
    height:100%;
    overflow:hidden
}
.project-overlay .slides{
    height:100%
}
.project-overlay .slide{
    height:100%;
    width:100vw;
    
    flex:none
}
.overlay-project__wrapper{
    width:100%;
    height:100%;
    position:relative;
    padding:5rem;
    
    display:flex;
    -webkit-box-pack:center;
    -moz-box-pack:center;
    box-pack:center;
    -webkit-justify-content:center;
    -moz-justify-content:center;
    -ms-justify-content:center;
    -o-justify-content:center;
    justify-content:center;
    -ms-flex-pack:center;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center
}
.overlay-project__wrapper > *{
    
    flex:none
}
.close__overlay{
    position:absolute;
    color:#fff;
    z-index:300;
    top:4rem;
    right:3.5rem;
    padding:1rem;
    cursor:pointer
}
.overlay-project__img{
    height:100%;
    background-color:#006CBE;
    display:block;
    width:300px;
    pointer-events:none;
    margin:10px;
    position:relative;
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover
}
.overlay-project__infos{
    font-size:1rem;
    height:100%;
    width:300px;
    line-height:2rem;
    
    flex:none;
    
    display:flex;
    -webkit-box-pack:center;
    -moz-box-pack:center;
    box-pack:center;
    -webkit-justify-content:center;
    -moz-justify-content:center;
    -ms-justify-content:center;
    -o-justify-content:center;
    justify-content:center;
    -ms-flex-pack:center;
    -webkit-box-align:center;
    -moz-box-align:center;
    box-align:center;
    -webkit-align-items:center;
    -moz-align-items:center;
    -ms-align-items:center;
    -o-align-items:center;
    align-items:center;
    -ms-flex-align:center;
    
    flex-direction:column;
    
}
.overlay-project__location,.overlay-project__map,.overlay-project__type,.overlay-project__puissance{
    background-color:gray;
    color:#fff;
    text-align:center;
    padding:.6em 0;
    font-size:1.8em;
    font-weight:100;
    width:100%;
    
    flex:none
}
.overlay-project__type img{
    height:1.8em;
    width:auto
}
.overlay-project__location{
    background-color:#2a2c87;
    margin-bottom:10px
}
.overlay-project__map{
    -webkit-box-flex:1;
    -moz-box-flex:1;
    box-flex:1;
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    flex:1;
    margin-bottom:10px
}
.overlay-project__type{
    background-color:#006cbe;
    margin-bottom:10px;
    font-size:1.5em
}
.overlay-project__puissance{
    background-color:#2385d0;
    padding:1.6em 1em
}
.project-overlay.projects-overlay-is-open{
    opacity:1;
    pointer-events:auto;
    visibility:visible
}
.Wallop-buttonPrevious,.Wallop-buttonNext{
    position:fixed;
    pointer-events:auto
}
@media (max-width: 61.9275em){
    .project-overlay .slide{
        height:90vh
    }
    .overlay-project__wrapper{
        height:100%;
        padding:1rem;
        
        flex-direction:column;
        
        -webkit-box-align:center;
        -moz-box-align:center;
        box-align:center;
        -webkit-align-items:center;
        -moz-align-items:center;
        -ms-align-items:center;
        -o-align-items:center;
        align-items:center;
        -ms-flex-align:center;
        -webkit-box-pack:center;
        -moz-box-pack:center;
        box-pack:center;
        -webkit-justify-content:center;
        -moz-justify-content:center;
        -ms-justify-content:center;
        -o-justify-content:center;
        justify-content:center;
        -ms-flex-pack:center
    }
    .overlay-project__map{
        display:none
    }
    .overlay-project__img{
        display:block;
        height:40%;
        width:80%;
        -webkit-box-flex:none;
        -moz-box-flex:none;
        box-flex:none;
        -webkit-flex:none;
        -moz-flex:none;
        -ms-flex:none;
        flex:none
    }
    .overlay-project__infos{
        height:auto;
        width:80%;
        font-size:.8rem;
        display:block;
        line-height:1.25;
        -webkit-box-flex:none;
        -moz-box-flex:none;
        box-flex:none;
        -webkit-flex:none;
        -moz-flex:none;
        -ms-flex:none;
        flex:none;
        -webkit-box-ordinal-group:-1;
        -moz-box-ordinal-group:-1;
        box-ordinal-group:-1;
        -webkit-order:-1;
        -moz-order:-1;
        order:-1;
        -ms-flex-order:-1
    }
    .overlay-project__type img{
        display:none
    }
    .close__overlay{
        font-size:2em;
        right:1rem;
        top:1rem;
        height:3rem;
        width:3rem;
        border-radius:50%;
        border:1px solid #fff;
        padding:0;
        text-align:center;
        line-height:1.25
    }
    .Wallop-buttonPrevious,.Wallop-buttonNext{
        width:30px;
        height:30px
    }
    .overlay-project__location,.overlay-project__type,.overlay-project__puissance{
        display:block
    }
}
.footer{
    background-color:#EFEFEF;
    padding:1rem;
    margin-bottom:3rem;
    text-align:center;
    font-size:.8em;
    color:#565656
}
.footer a{
    text-decoration:none;
    color:#565656
}
.footer p{
    margin:0
}
.menu{
    width:auto
}
.menu__overlay--open--js .menu__item > *{
    -webkit-box-flex:1;
    -webkit-flex:1;
    -ms-flex:1;
    flex:1;
    width:auto
}
.menu__overlay--open--js .menu__list{
    width:calc(100% - 1rem)
}
.menu__wrapper{
    -webkit-box-pack:end;
    -webkit-justify-content:flex-end;
    -ms-flex-pack:end;
    justify-content:flex-end
}
.menu__toggle__label{
    background-image:url(../img/ui/menu.png)
}
.menu__overlay--open--js .menu__sub li{
    width:100%
}
.map-implantations > img{
    background-color:#2a2c87
}
