.formulaire {

  fieldset {
    border-color: $blue;
    margin-bottom: 1em;
    border: none;
  }

  legend {
    text-transform: uppercase;
    color: $blue;
    font-weight: bold;
    font-size: 1.4em;
  }

  input[type=text],
  input[type=textarea],
  select {
    width: 100%;
    padding: 0 0.5em;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid $blue;
    border-radius: 4px;
    border-color: $blue;
    text-transform: uppercase;
    box-sizing: border-box;
    color: $blue;
  }

  button[type=submit] {
      width: 100%;
      background-color: $blue;
      color: white;
      padding: 14px 20px;
      margin: 8px 0;
      border: none;
      border-radius: 4px;
      font-size: 110%;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        background-color: lighten($color: $blue, $amount: 10)
      }
  }

  input[type=submit]:hover {
      background-color: #45a049;
  }

  .field {
    display: flex;
    align-items: center;
    
    
    > * {
      flex: 1;
    }
  }

    .heading {
      background: none;
      //color: $blue;
      padding: 0;
      flex: 0.5;
      color: #111;
      font-size: 90%;
    }

    .required {
      &:after {
        content: "*";
        color: red;
        margin-left: 0.5em;
        font-weight: bold;
      }
    }

    fieldset > div {
      background: #f1f1f1;
      padding: 0 0.5em;
    }

    fieldset > div:nth-child(2n+3) {
      background: #fff;
    }

    .notifications {
      padding: 1em;
    }

    .errors {
      border: 2px solid red;
      padding: 1em;
      margin: 0;
    }

}

.formulaire__success {
  text-align: center;

  p {
    text-align: center;
  }
}